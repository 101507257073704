import request from '@/utils/request'

export function getCustomerUserPage(params) {
  return request({
    url: '/rfidpcs/customer/page',
    method: 'get',
    params: params
  })
}

export function addCustomerUser(data) {
  return request({
    url: '/rfidpcs/customer/',
    method: 'post',
    data
  })
}

export function frozenCustomerUser(userId) {
  return request({
    url: '/rfidpcs/customer/frozen/' + userId,
    method: 'put'
  })
}

export function thawCustomerUser(userId) {
  return request({
    url: '/rfidpcs/customer/thaw/' + userId,
    method: 'put'
  })
}

export function deleteCustomerUser(userId) {
  return request({
    url: '/rfidpcs/customer/' + userId,
    method: 'delete'
  })
}

export function updateCustomerUser(data) {
  return request({
    url: '/rfidpcs/customer/',
    method: 'put',
    data
  })
}

export function updatePassword(data) {
  return request({
    url: '/rfidpcs/customer/updatePassword',
    method: 'put',
    data
  })
}

export function resetUserPassword(userId) {
  return request({
    url: '/rfidpcs/customer/resetPassword/' + userId,
    method: 'put'
  })
}
