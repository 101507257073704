import Cookies from 'js-cookie'
import request from '@/utils/request'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function removeRemoteToken() {
  return request({
    url: '/rfidpcs/auth/logout',
    method: 'post'
  })
}
