import axios from 'axios'
import {Message} from 'element-ui'
import store from '@/store'
import {getToken, removeToken} from '@/utils/auth'
import qs from 'qs'

// create an axios instance
const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    // timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        if (store.getters.token) {
            config.headers['Accept-Language'] = store.getters.language
            config.headers['X-Token'] = getToken()
        }
        if (config.method === 'get') {
            config.url += config.params ? ('?' + qs.stringify(config.params)) : ''// 格式化参数
            config.params = undefined
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {

        // 下载文件，直接返回response
        if (response.headers['content-disposition']) {
            return response
        }
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {

            if (res.code === 207) {
                removeToken()
                location.reload()
            } else {
                Message({
                    message: res.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
