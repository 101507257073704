<template>
  <el-container style="height: 100vh">

    <!--      头部    -->
    <el-header style="text-align: right; font-size: 14px;padding-right: 20px;margin-left: 210px">
      <a href="#" @click="isCollapse = !isCollapse;"
         style="font-size: 25px;color:#909399;float: left;margin-left: 15px">
        <i :class="collpaseIcon"></i>
      </a>

      <span
          style="float:left;margin-left: 60px; font-size: 26px; font-family: 微软雅黑,serif; font-style: oblique; font-weight: bold; color: rgb(125, 125, 127);">
      </span>

      <div>
        <lang-select class="set-language" style="margin-right: 20px; font-size: 22px"/>


        <el-dropdown trigger="click" @command="handleCommand">
          <span>
            <i class="el-icon-user-solid" style="margin-right: 15px; font-size: 22px"></i>
            <span>{{ userName }}&nbsp;&nbsp;</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="updatePassword">{{$t("common.updatePassword")}}</el-dropdown-item>
            <el-dropdown-item command="exit">{{$t("common.logout")}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>

    <!--    主体页面    -->
    <el-container>

      <!--    左侧菜单    -->
      <el-aside style="background-color: rgb(238, 241, 246);overflow-x: hidden;width: 210px">


        <el-scrollbar wrapClass="scrollbar-wrapper" class="sidebar-container">
          <div style="padding: 20px 0 0 20px">
            <img :src="require('@/assets/Checkpoint-Primary-Logo-White.png')" height="25">
          </div>

          <el-menu :default-active="currentMenu"
                   class="el-menu-vertical-demo"
                   :router="true"
                   :collapse="isCollapse"
                   :unique-opened="true"
                   background-color="#304156"
                   text-color="#bfcbd9"
                   active-text-color="#409EFF">

            <sidebar-item v-for="route in permission_routers" :key="route.name" :item="route"
                          :base-path="route.path"></sidebar-item>
          </el-menu>
        </el-scrollbar>
      </el-aside>

      <!--    右侧内容    -->
      <el-main>
        <!--路由渲染-->
        <router-view :key="routerKey"></router-view>
      </el-main>
    </el-container>


    <!--修改密码-->
    <el-dialog :title="$t('customer.updatePassword')" :visible.sync="udpFormShow" width="800px" :close-on-click-modal="false">
      <el-form ref="updatePasswordForm" :model="userPassword" label-width="180px" :rules="udpValidateRule">
        <el-form-item :label="$t('customer.oldPassword')" prop="oldPassword">
          <el-input v-model="userPassword.oldPassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.newPassword')" prop="newPassword">
          <el-input v-model="userPassword.newPassword"></el-input>
        </el-form-item>
        <el-form-item :label="$t('customer.confirmPassword')" prop="confirmPassword">
          <el-input v-model="userPassword.confirmPassword"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="udpFormShow = false">{{$t('common.cancel')}}</el-button>
        <el-button type="primary" @click="doSubmit()">{{$t('common.confirm')}}</el-button>
      </div>
    </el-dialog>

  </el-container>
</template>

<script>
import {mapGetters} from 'vuex'
import {removeRemoteToken,removeToken} from "@/utils/auth";
import SidebarItem from './sidebar/SidebarItem'
import {getStorageItem} from "@/utils/storage";
import LangSelect from "@/components/LangSelect";
import { reqAndLenValidate } from '@/utils/commonValidator';
import {
  updatePassword
} from '@/api/platform/customer'

export default {
  name: "index",
  components: {SidebarItem, LangSelect},
  data() {
    return {
      isCollapse: false,
      currentMenu: 'product',
      systemConfig: '',
      routerKey: '',
      udpFormShow:false,
      userPassword:{
        oldPassword:'',
        newPassword:'',
        confirmPassword:''
      },
      udpValidateRule:{
        //paraName: [{required: true, message: this.$t('lanCusSysPara.tipParaNameRequired'), trigger: 'blur'}],
        oldPassword: [{required: true, validator:(rule,value,callback)=>{reqAndLenValidate(rule,value,callback,50)}, trigger: 'blur'}],
        newPassword: [{required: true, validator:(rule,value,callback)=>{reqAndLenValidate(rule,value,callback,50)}, trigger: 'blur'}],
        confirmPassword: [{required: true, validator:(rule,value,callback)=>{reqAndLenValidate(rule,value,callback,200)}, trigger: 'blur'}]
      }
    }
  },
  computed: {
    ...mapGetters([
      'permission_routers',
      'userName'
    ]),
    collpaseIcon: function () {
      // 左侧导航栏是否展开状态的图标
      // 如果是展开状态就图标向右，否则图标向左
      return this.isCollapse ? "el-icon-s-fold" : "el-icon-s-unfold";
    }
  },
  created() {
    this.currentMenu = this.$route.path
    this.systemConfig = getStorageItem('SYSTEM_CONFIG') || {}
  },
  mounted() {
    // document.getElementsByTagName("title")[0].innerText = this.systemConfig.title;
  },
  watch: {
    '$route' (to) {
      this.routerKey = to.name
    }
  },
  methods: {
    handleCommand(command) {
      if (command === 'exit') {
        removeRemoteToken().then(res => {
        removeToken()
        location.reload()
      }).catch(() => {
        removeToken()
        location.reload()
      })
      }else if(command === 'updatePassword'){
        this.userPassword.oldPassword = '';
        this.userPassword.newPassword = '';
        this.confirmPassword = '';
        this.udpFormShow = true;
      }
    },
    doSubmit() {
      let that = this;
      that.$refs['updatePasswordForm'].validate((valid) => {
        if (valid) {
          if(that.userPassword.newPassword !== that.userPassword.confirmPassword){
            that.$message.error(that.$t('customer.notEqualsErrTip'))
          }else if(that.userPassword.newPassword === that.userPassword.oldPassword){
            that.$message.error(that.$t('customer.samePasswordErrTip'))
          }else{
            updatePassword(that.userPassword).then(res => {
              that.udpFormShow = false
              that.$message.success(that.$t('common.operationSuccess'))
            })
          }
        }
      });
    }
  }
}
</script>

<style>
.el-header {
  color: #333;
  line-height: 60px;
}


.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}

/*整体显示区域布局样式*/
.el-container {
  height: 100%;
}

.el-header,
.el-main {
  padding: 0;
}

/*左边导航栏具体样式*/
.el-menu-vertical-demo.el-menu {
  text-align: left;
  height: calc(100vh - 60px);
  padding: 0;
}

el-container > .el-menu-vertical-demo.el-menu {
  padding: 0;
}

.el-submenu .el-menu-item,
.el-menu-item {
  min-width: 50px;
}

.el-menu-item {
  padding: 0;
}

.el-menu--collapse i {
  padding-right: 50px;
}

.el-menu--collapse {
  width: 50px;
}
</style>
